<script setup>
import { computed } from 'vue';
import { findValue, mapArray } from '@emobg/web-utils';
import { GRAYSCALE, PLACEMENTS } from '@emobg/web-components';
import { useStoreModule } from '@/composable/Store/useStoreModule';
import { useTranslations } from '@/composable/App/Translations';
import {
  GETTERS as BookingTypeGetters,
  nameSpace as BookingTypeNameSpace,
} from '@/vue/stores/BookingType/BookingTypeStore';

const { $t } = useTranslations();
const {
  getCurrentBookingType,
  getBookingTypeCollection,
} = useStoreModule(BookingTypeNameSpace, {
  getters: [
    BookingTypeGetters.getBookingTypeCollection,
    BookingTypeGetters.getCurrentBookingType,
  ],
});

const emit = defineEmits(['change:booking-type']);
const bookingTypeOptions = computed(() => mapArray(getBookingTypeCollection.value, bookingType => ({
  label: bookingType.name,
  value: bookingType.code,
})));

const onClick = bookingTypeCode => {
  const bookingType = findValue(getBookingTypeCollection.value, { code: bookingTypeCode });
  emit('changed:booking-type', bookingType);
};

</script>

<template>
  <div
    class="BookingTypeSelectorComponent"
    data-test-id="booking_type-component"
  >
    <div class="d-flex">
      <ui-label :label="$t('views.home.booking_type_label')" />
      <ui-tooltip
        :placement="PLACEMENTS.rightStart"
        :color="GRAYSCALE.ink"
        :tooltip="$t('views.home.booking_type_tooltip')"
      >
        <ui-icon
          :icon="ICONS.infoCircle"
          :size="SIZES.xSmall"
          class="ml-1"
        />
      </ui-tooltip>
    </div>
    <ui-button-segments
      :value="getCurrentBookingType.code"
      :options.prop="bookingTypeOptions"
      class="BookingTypeSelectorComponent__selector d-block"
      data-test-id="booking_type-buttons"
      @clickbuttonsegment="({ detail }) => onClick(detail)"
    />
  </div>
</template>

<style lang="scss">
.BookingTypeSelectorComponent {
  .Ui-Tooltip__tooltip {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15) !important;
  }

  &__selector {
    width: max-content;

    .Ui-Button {
      width: 66px;
      min-width: auto;
    }
  }
}
</style>

<template>
  <ui-modal
    :open="isOpen"
    :size="SIZES.medium"
    :header="title"
    data-test-id="corporate_validation-modal"
  >
    <div slot="body">
      {{ text }}

      <RequiredDocumentsComponent
        :documents="requiredDocumentsWithTextAndStatus"
        :is-loading="isRequiredDocumentsLoading"
        class="mt-4"
      />
    </div>
    <div
      slot="footer"
      class="d-flex justify-content-end"
    >
      <ui-button
        v-if="actionablePendingValidations.length > 0"
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
        class="mr-3"
        data-test-id="cancel-button"
        @clickbutton="onCancel"
      >
        {{ $t('buttons.cancel') }}
      </ui-button>
      <ui-button
        v-bind="fetchButtonSpecs()"
        data-test-id="continue-button"
        @clickbutton="onClickContinue"
      >
        {{ actionablePendingValidations.length > 0 ? $t('buttons.continue') : $t('buttons.got_it') }}
      </ui-button>
    </div>
  </ui-modal>
</template>

<script>
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import {
  camelCaseKeys, DATE_FORMAT, findValue, getValue, LOG_TYPE, logger, pickValue,
} from '@emobg/web-utils';
import {
  fetchRequiredDocuments,
  hasCompletedBadge,
  hasUploadedDrivingLicense,
  isRequiredDocumentsLoading,
  requiredDocuments,
} from '@/stores/UserValidations/UserValidationsMapper';
import {
  fetchUserDetails,
  userData,
} from '@/stores/User/UserData/UserDataMapper';
import { useDrivingLicenseRoadblock } from '@/composable/User/documents/drivingLicense';
import { useKeycardRoadblock } from '@/composable/User/documents/keycard';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { fetchUserAccount } from '@/stores/User/UserMapper';
import { fetchCSOperator } from '@/stores/CSOperator/CSOperatorMapper';
import { REQUIRED_DOCUMENT_EVALUATED_STATUS, REQUIRED_DOCUMENT_STATUS, REQUIRED_DOCUMENT_TYPES } from '@/constants/userValidation';
import { useTheme } from '@/composable/Theme/useTheme';
import {
  evaluateBadge, evaluateDriverLicense, isBadgePending, isDriverLicensePending,
} from '@/domains/Bookings/components/UserValidationModals/helpers/corporateValidations';
import RequiredDocumentsComponent from './components/RequiredDocuments/RequiredDocumentsComponent';

export default {
  name: 'CorporateValidationComponent',

  components: {
    RequiredDocumentsComponent,
  },

  model: {
    prop: 'isOpen',
    event: 'update:is-open',
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    selectedLocation: {
      type: Object,
      default: () => ({}),
    },
    onValidationCompleted: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { showRoadblock: showDrivingLicense } = useDrivingLicenseRoadblock();
    const { showRoadblock: showKeycard } = useKeycardRoadblock();
    const { notifyError } = useNotifications();
    const { fetchButtonSpecs } = useTheme();

    return {
      showDrivingLicense,
      showKeycard,
      notifyError,
      fetchButtonSpecs,
    };
  },

  data() {
    return {
      formattedDate: null,
      text: '',
      title: '',
      csOperatorConfig: {},
    };
  },

  computed: {
    requiredDocuments,
    isRequiredDocumentsLoading,
    hasUploadedDrivingLicense,
    hasCompletedBadge,
    userData,

    requiredDocumentsWithTextAndStatus() {
      const evaluations = this.evaluatedRequiredDocuments;

      return filter(map(this.requiredDocuments, requiredDocument => ({
        ...requiredDocument,
        status: evaluations[requiredDocument.type] || REQUIRED_DOCUMENT_STATUS.missing,
        text: this.$t(
          `BookingView.CorporateValidationComponent.documents.${requiredDocument.type}.${evaluations[requiredDocument.type] === REQUIRED_DOCUMENT_EVALUATED_STATUS.assignByAdmin ? 'assign_by_admin' : 'default'}.text`,
        ),
        key: requiredDocument.type,
      })), document => document.status !== REQUIRED_DOCUMENT_EVALUATED_STATUS.noShow);
    },
    actionablePendingValidations() {
      const drivingLicenseStatus = getValue(findValue(this.requiredDocumentsWithTextAndStatus, { type: REQUIRED_DOCUMENT_TYPES.drivingLicenseReview }), 'status');
      const driverLicenseReviewStatus = getValue(findValue(this.requiredDocumentsWithTextAndStatus, { type: REQUIRED_DOCUMENT_TYPES.drivingLicense }), 'status');
      const badgeStatus = getValue(findValue(this.requiredDocumentsWithTextAndStatus, { type: REQUIRED_DOCUMENT_TYPES.badge }), 'status');
      const restrictiveBadgeStatus = getValue(findValue(this.requiredDocumentsWithTextAndStatus, { type: REQUIRED_DOCUMENT_TYPES.restrictiveBadge }), 'status');

      const validations = [
        {
          key: 'driver_license',
          status: isDriverLicensePending({ hasUploadedDrivingLicense: this.hasUploadedDrivingLicense, drivingLicenseStatus, driverLicenseReviewStatus }),
          method: this.showDrivingLicenseRoadblock,
        },
        {
          key: 'badge',
          status: isBadgePending({ hasCompletedBadge: this.hasCompletedBadge, badgeStatus, restrictiveBadgeStatus }),
          method: this.showKeycardRoadblock,
        },
      ];

      return filter(validations, 'status');
    },
    evaluatedRequiredDocuments() {
      if (isEmpty(this.csOperatorConfig)) {
        return {};
      }
      const requiredProps = ['add_automatic_badge', 'badge_required', 'driving_license_required', 'dl_review_days', 'restrictive_badge_provider_id'];
      const {
        addAutomaticBadge, badgeRequired, drivingLicenseRequired, dlReviewDays, restrictiveBadgeProviderId,
      } = camelCaseKeys(pickValue(getValue(this.csOperatorConfig, 'configuration'), requiredProps));

      const driverLicenseStatus = getValue(findValue(this.requiredDocuments, { type: REQUIRED_DOCUMENT_TYPES.drivingLicense }), 'status');
      const driverLicenseReviewStatus = getValue(findValue(this.requiredDocuments, { type: REQUIRED_DOCUMENT_TYPES.drivingLicenseReview }), 'status');
      const badgeStatus = getValue(findValue(this.requiredDocuments, { type: REQUIRED_DOCUMENT_TYPES.badge }), 'status');
      const restrictiveBadgeStatus = getValue(findValue(this.requiredDocuments, { type: REQUIRED_DOCUMENT_TYPES.restrictiveBadge }), 'status');

      const driverLicenseStatusComputed = driverLicenseStatus || driverLicenseReviewStatus;
      const badgeStatusComputed = badgeStatus || restrictiveBadgeStatus;

      return {
        [REQUIRED_DOCUMENT_TYPES.drivingLicenseReview]: evaluateDriverLicense({ dlReviewDays, drivingLicenseRequired, driverLicenseStatusComputed, formattedDate: this.formattedDate }),
        [REQUIRED_DOCUMENT_TYPES.drivingLicense]: evaluateDriverLicense({ dlReviewDays, drivingLicenseRequired, driverLicenseStatusComputed, formattedDate: this.formattedDate }),
        [REQUIRED_DOCUMENT_TYPES.badge]: evaluateBadge({ addAutomaticBadge, badgeRequired, restrictiveBadgeProviderId, badgeStatusComputed }),
        [REQUIRED_DOCUMENT_TYPES.restrictiveBadge]: evaluateBadge({ addAutomaticBadge, badgeRequired, restrictiveBadgeProviderId, badgeStatusComputed }),
      };
    },

  },

  async created() {
    // Used in /v4/availability
    const drivingLicenseExpiresSoon = get(this.selectedLocation, 'user_documents.driving_license_review_expires_soon');
    const nextDrivingLicenseReviewDate = get(this.selectedLocation, 'user_documents.next_driving_license_review_date');

    if (drivingLicenseExpiresSoon) {
      this.formattedDate = moment(nextDrivingLicenseReviewDate).format(DATE_FORMAT.dob);
      this.title = this.$t('BookingView.CorporateValidationComponent.title_about_to_expiry');
      this.text = this.$t('BookingView.CorporateValidationComponent.about_to_expiry_text', { expirationDate: this.formattedDate });
    } else {
      this.formattedDate = null;
      this.title = this.$t('BookingView.CorporateValidationComponent.title_incomplete');
      this.text = this.$t('BookingView.CorporateValidationComponent.incomplete_text');
    }

    const locationCSOperator = get(this.selectedLocation, 'cs_operator_uuid');

    if (isEmpty(this.requiredDocuments)) {
      fetchRequiredDocuments({
        csOperatorUuid: locationCSOperator,
      });
    }

    this.csOperatorConfig = await this.fetchCSOperator(locationCSOperator);
  },

  methods: {
    fetchCSOperator,
    fetchUserAccount,
    onClickContinue() {
      const validation = findValue(this.actionablePendingValidations, 'status');

      if (validation) {
        validation.method();
      }

      this.$emit('update:is-open', false);
    },
    async showDrivingLicenseRoadblock() {
      this.showDrivingLicense({
        hasCompletedBadge: this.hasCompletedBadge,
        onDrivingLicenseCreated: () => {
          this.fetchUserData();
        },
        onAfterClose: () => {
          if (!this.hasCompletedBadge) {
            this.showKeycardRoadblock();
          }
        },
        onError: () => {
          this.showErrorNotification();
        },
      });
    },

    showKeycardRoadblock() {
      this.showKeycard({
        onKeycardRequested: () => {
          this.onValidationCompleted();
        },
        onKeycardLinked: () => {
          this.onValidationCompleted();
        },
        onKeycardPickedUp: () => {
          this.onValidationCompleted();
        },
        onAfterClose: () => {
          this.fetchUserData();
        },
        onError: () => {
          this.showErrorNotification();
        },
      });
    },
    fetchUserData() {
      return Promise.all([
        fetchUserDetails(),
        this.fetchUserAccount({
          userUuid: get(this, 'userData.uuid'),
        }),
      ]).catch(() => {
        logger.message('Error refreshing data', LOG_TYPE.error);
      });
    },
    showErrorNotification() {
      this.$fullScreen.close();
      this.notifyError({
        text: this.$t('notifications.whooops'),
        textAction: this.$t('buttons.ok'),
      });
    },
    onCancel() {
      this.$emit('update:is-open', false);
    },
  },
};
</script>

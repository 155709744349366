<template>
  <article class="phLocation phLocation-placeholder">
    <header class="d-flex align-items-center my-4">
      <div class="phLocation_icon mr-2" />
      <div class="phLocation_name" />
    </header>
    <div
      v-for="index in 4"
      :key="index"
      class="Vehicle-placeholder mb-3"
    />
  </article>
</template>

<script>
export default {
  name: 'LocationPlaceholder',
};
</script>

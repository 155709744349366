import { REQUIRED_DOCUMENT_EVALUATED_STATUS, REQUIRED_DOCUMENT_STATUS } from '@/constants/userValidation';
import { isNilValue } from '@emobg/web-utils';

/**
 * @function evaluateDriverLicense
 * @desc Returns the status of the required Driver License
 *
 * @param {Number} dlReviewDays                 - Days between DL reviews by Admin
 * @param {Boolean} drivingLicenseRequired      - Flag to determine it's require to validate DL
 * @param {String} driverLicenseStatusComputed  - Current DL document status
 * @example <caption>Example usage of evaluateDriverLicense.</caption>
 *  * // returns "assign_by_admin"
 * evaluateDriverLicense({ dlReviewDays: 20, drivingLicenseRequired: true, driverLicenseStatusComputed: "completed" })
 * @return {String}
 */
export const evaluateDriverLicense = ({
  dlReviewDays, drivingLicenseRequired, driverLicenseStatusComputed, formattedDate,
}) => {
  const isCompleted = driverLicenseStatusComputed === REQUIRED_DOCUMENT_STATUS.completed;
  if (drivingLicenseRequired && !dlReviewDays) {
    return isCompleted && formattedDate ? REQUIRED_DOCUMENT_STATUS.aboutToExpiry : driverLicenseStatusComputed;
  }
  return isCompleted ? driverLicenseStatusComputed : REQUIRED_DOCUMENT_EVALUATED_STATUS.assignByAdmin;
};

/**
 * @function evaluateBadge
 * @desc Returns the status of the required Driver License
 *
 * @param {Boolean} addAutomaticBadge          - Flag to determine that operator assigns badge directly
 * @param {Boolean} badgeRequired              - Flag to determine it's require to validate the badge
 * @param {String} restrictiveBadgeProviderId  - The operator badge provider
 * @param {String} badgeStatus                 - Current badge status
 * @example <caption>Example usage of evaluateBadge.</caption>
 *  * // returns "no_show"
 * evaluateBadge({ addAutomaticBadge: false, badgeRequired: false, restrictiveBadgeProviderId: null, badgeStatus: "missing" })
 * @return {String}
 */
export const evaluateBadge = ({
  addAutomaticBadge, badgeRequired, restrictiveBadgeProviderId, badgeStatusComputed,
}) => {
  if (restrictiveBadgeProviderId) {
    return badgeStatusComputed === REQUIRED_DOCUMENT_STATUS.completed
      ? badgeStatusComputed
      : REQUIRED_DOCUMENT_EVALUATED_STATUS.assignByAdmin;
  }

  if (!badgeRequired && !addAutomaticBadge) {
    return REQUIRED_DOCUMENT_EVALUATED_STATUS.noShow;
  }

  if (badgeRequired && addAutomaticBadge) {
    return REQUIRED_DOCUMENT_STATUS.completed;
  }

  return badgeStatusComputed;
};

/**
 * @function isDriverLicensePending
 * @desc Returns the status of the required Driver License
 *
 * @param {Boolean} hasUploadedDrivingLicense   - Flag to determine if user has uploaded DL
 * @param {String} badgeStatus        - User's DL status
 * @param {String} restrictiveBadgeStatus    - User's DL review status
 * @example <caption>Example usage of isDriverLicensePending.</caption>
 *  * // returns true
 * isDriverLicensePending({ hasUploadedDrivingLicense: true, drivingLicenseStatus: null, driverLicenseReviewStatus: "missing" })
 * @return {Boolean}
 */
export const isDriverLicensePending = ({ hasUploadedDrivingLicense, drivingLicenseStatus, driverLicenseReviewStatus }) => !hasUploadedDrivingLicense
    && drivingLicenseStatus !== REQUIRED_DOCUMENT_EVALUATED_STATUS.assignByAdmin
    && driverLicenseReviewStatus !== REQUIRED_DOCUMENT_EVALUATED_STATUS.assignByAdmin;

/**
 * @function isBadgePending
 * @desc Returns the status of the required Driver License
 *
 * @param {Boolean} hasCompletedBadge        - Flag to determine if user has uploaded the badge
 * @param {String} badgeStatus               - User's badge status
 * @param {String} restrictiveBadgeStatus    - User's restrictive badge status
 * @example <caption>Example usage of isBadgePending.</caption>
 *  * // returns true
 * isBadgePending({ hasCompletedBadge: true, badgeStatus: null, restrictiveBadgeStatus: "missing" })
 * @return {Boolean}
 */
export const isBadgePending = ({ hasCompletedBadge, badgeStatus, restrictiveBadgeStatus }) => !hasCompletedBadge
    && ![REQUIRED_DOCUMENT_EVALUATED_STATUS.assignByAdmin, REQUIRED_DOCUMENT_EVALUATED_STATUS.noShow].includes(badgeStatus)
    && ![REQUIRED_DOCUMENT_EVALUATED_STATUS.assignByAdmin, REQUIRED_DOCUMENT_EVALUATED_STATUS.noShow].includes(restrictiveBadgeStatus)
    && !(isNilValue(badgeStatus) && isNilValue(restrictiveBadgeStatus));

<template>
  <article
    v-scroll-into-location="(scrolling)"
    data-test-id="location-item"
    class="Location py-4 w-100"
    :class="id"
  >
    <LocationDetailsModal
      v-if="locationDetailsModal.isOpen"
      v-bind="locationDetailsModal"
      @close-modal="() => locationDetailsModal.isOpen = false"
    />
    <LocationHeaderMyBookingComponent
      v-if="hasNewHeader"
      :dates="headerDates"
      :locations="headerAddress"
      @address-clicked="$emit('selected', location)"
    />
    <template v-else>
      <header
        v-if="$slots.header"
        class="Location_header mb-3"
        @click="setMarkerSelected"
      >
        <slot
          name="header"
          :icon="getParkingIcon"
        />
      </header>

      <header
        v-else
        class="Location__header mb-3 d-flex align-items-start"
        @click="setMarkerSelected"
      >
        <div class="mr-auto d-flex align-items-center">
          <img
            :src="getParkingIcon"
            class="mr-2"
            alt="Parking icon"
            width="24px"
          >
          <h3 class="mb-0">
            <span class="emobg-font-weight-bold emobg-color-ink">
              {{ titleAddress.primaryName }}
            </span>
            <span
              v-if="titleAddress.secondaryName"
              class="emobg-color-ink-light"
            >
              | {{ titleAddress.secondaryName }}
            </span>
          </h3>
        </div>
        <div
          v-if="isBookingOnBehalfBlocked"
          class="d-flex flex-column"
        >
          <span class="d-flex align-items-center">
            <ui-icon
              :color="COLORS.danger"
              :icon="ICONS.alertAloneFull"
              :size="ICONS_SIZES.small"
            />
            <span class="ml-2 emobg-font-small">
              {{ $t("new_booking.booking_list.booking_on_behalf.employee_has_invalid_documents.location.info") }}
            </span>
          </span>
          <span class="ml-2">
            <ui-button
              v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.TERTIARY })"
              :size="SIZES.small"
              data-test-id="booking_on_behalf-button"
              @clickbutton="$emit('on:booking-on-behalf-roadblock')"
            >
              <p class="emobg-font-small">
                {{ $t("new_booking.booking_list.booking_on_behalf.employee_has_invalid_documents.location.more_info") }}
              </p>
            </ui-button>
          </span>
        </div>
      </header>
    </template>
    <template v-for="vehicle in vehicles">
      <Vehicle
        v-if="isElementVisible"
        :key="vehicle.uuid"
        v-bind="pick(booking, VEHICLE_REQUIRED_PROPS)"
        :booking="booking"
        :availability-time="availabilityTime"
        :vehicle="vehicle"
        :type="type"
        :location="location"
        :visible="isElementVisible"
        :map-manager="mapManager"
        :booked-time="bookedTime"
        :expanded="expanded"
        :is-book-button-loading="areBookButtonsLoading"
        :on-click-book-button="value => $emit('update:book-buttons-loading', value)"
        :class="{
          'Vehicle--selected': selectedBookingUuid && selectedBookingUuid === bookingUuid
        }"
        @click="selectVehicle({ vehicle, location })"
      />
      <div
        v-else
        :key="vehicle.uuid"
        class="Vehicle-placeholder mb-3"
      />
    </template>

    <template v-for="(category, index) in vehiclesCategories">
      <VehicleCategory
        :key="index"
        :vehicle-category="category"
        :location="location"
        :map-manager="mapManager"
        :expanded="expanded"
        :booked-time="bookedTime"
      />
    </template>
  </article>
</template>

<script>
import moment from 'moment';
import get from 'lodash/get';
import pick from 'lodash/pick';
import isPlainObject from 'lodash/isPlainObject';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import { getValue } from '@emobg/web-utils';
// Utils
import { printableAddress, printableDate } from '@/helpers/booking/locationHelpers';
// Components
import Vehicle from '@/vue/components/organism/Vehicle/Vehicle';
import { USER_STATUS } from '@/constants/userStatus.const';
import LocationHeaderMyBookingComponent from '@/components/Location/LocationHeader/LocationHeaderMyBookingComponent';
import VehicleCategory from '@/vue/components/organism/Vehicle/VehicleCategory';
import LocationDetailsModal from '@/vue/components/organism/common/LocationDetails/LocationDetailsModal';
// Composables
import { useAvailability } from '@/composable/Availability/useAvailability';
// Managers
import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';
// Mixins
import EventHandlerMixin from '@/mixins/EventHandler';
import { addNodeElement, checkNodeVisibility, isVisible } from '@/utils/visibleOnViewport';
// Directives
import scrollIntoLocation from '@/directives/scrollIntoLocation';

// Services
import { instance as mapMarkerSelector } from '@/services/MapMarkerSelector';
// Constants
import {
  SEGMENT_ENUMERATE_VALUES,
  SEGMENT_EVENTS,
  SEGMENT_PARAM_NAMES,
} from '@/vue/constants';

import { isActivated, isPreregistered } from '@/helpers/user/status/userStatusHelper';
import { isLongDistance } from '@/helpers/booking/bookingHelpers';
import { getCurrentBookingType } from '@/stores/Booking/stores/BookingType/BookingTypeMapper';
import { getCurrentOperatorCurrency } from '@/stores/CSOperator/CSOperatorMapper';

import { composeViewItemListData, mapLocationData, mapVehiclesData } from '@/helpers/user/tracking/ecommerceEvents';

import { useTrackingGTM } from '@/composable/GTM/gtm';
import { useSegment } from '@/composable/Segment/segment';
import { useTheme } from '@/composable/Theme/useTheme';

import { GTM_EVENTS } from '@/constants/gtm';
import { LOCATION_TYPES } from '@/constants/location.const';

export default {

  directives: {
    scrollIntoLocation,
  },

  components: {
    Vehicle,
    VehicleCategory,
    LocationDetailsModal,
    LocationHeaderMyBookingComponent,
  },

  mixins: [
    EventHandlerMixin,
  ],

  props: {
    location: {
      type: Object,
      required: true,
    },
    vehicleId: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'bookinglist',
    },
    mapManager: {
      type: Object,
      required: true,
    },
    availabilityTime: {
      type: Object,
      default: () => ({}),
    },
    bookingPeriod: {
      type: Object,
      default: () => ({}),
    },
    bookingUuid: {
      type: String,
      default: '',
    },
    booking: {
      type: Object,
      default: () => ({}),
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    isBookingRequest: {
      type: Boolean,
      default: false,
    },
    hasNewHeader: {
      type: Boolean,
      defaut: false,
    },
    destinationLocation: {
      type: Object,
      default: () => ({}),
    },
    locationsVisibility: {
      type: Array,
      default: () => [],
    },
    selectedBookingUuid: {
      type: String,
      default: '',
    },
    city: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: '',
    },
    areBookButtonsLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { getSelectedEmployee, isBookingOnBehalf } = useAvailability();
    const { trackEcommerceEvent } = useTrackingGTM();
    const { trackSegment } = useSegment();
    const { fetchButtonSpecs } = useTheme();

    return { getSelectedEmployee, isBookingOnBehalf, trackEcommerceEvent, trackSegment, fetchButtonSpecs };
  },

  data() {
    return {
      locationDetailsModal: {
        isOpen: false,
        mapManager: this.mapManager,
        parentLocation: null,
      },
      display: {
        selected: false,
      },
    };
  },
  computed: {
    getCurrentBookingType,
    getCurrentOperatorCurrency,
    titleAddress() {
      const splittedAddress = (this.printableAddress(this.location) || '').split(' | ');
      return {
        primaryName: getValue(splittedAddress, '[0]', ''),
        secondaryName: getValue(splittedAddress, '[1]', ''),
      };
    },
    id() {
      return `js-location-${this.location.uuid}`;
    },

    classId() {
      return `.${this.id}`;
    },

    isElementVisible() {
      return isVisible(this.locationsVisibility, this.classId);
    },
    scrolling() {
      return this.display.selected && this.type !== 'mybookings';
    },
    vehicles() {
      if (this.isStackLocation && !this.isBookingRequest) {
        return [];
      }

      let vehicles;

      if (isPlainObject(get(this, 'location.vehicle'))) {
        vehicles = [this.location.vehicle];
      } else if (this.vehicleId) {
        vehicles = find(get(this, 'location.vehicles'), ['uuid', this.vehicleId]);
      } else {
        vehicles = get(this, 'location.vehicles');
      }

      return orderBy(vehicles, vehicleObject => get(vehicleObject, 'occupation.available'), 'desc');
    },
    vehiclesCategories() {
      return this.isStackLocation ? this.location.categories : [];
    },
    bookedTime() {
      return {
        start: moment(get(this, 'bookingPeriod.start')),
        end: moment(get(this, 'bookingPeriod.end')),
      };
    },
    getParkingIcon() {
      return mapMarkerSelector.parkingIcon();
    },
    isStackLocation() {
      return get(this, 'location.type') === LOCATION_TYPES.stack;
    },
    isBookingOnBehalfBlocked() {
      const employeeSelectedStatus = get(this, 'getSelectedEmployee.user_status', USER_STATUS.deactivated);
      const employeeSelectedHasInvalidDocuments = get(this, 'location.user_documents.hasInvalidDocuments');
      const employeeOpenFleetStatus = get(this, 'getSelectedEmployee.open_fleet_status', USER_STATUS.deactivated);
      const employeeCanBookOpenFleet = get(this, 'location.open', true)
        ? isActivated(employeeOpenFleetStatus)
        : true;

      return this.isBookingOnBehalf
        && (employeeSelectedHasInvalidDocuments || !employeeCanBookOpenFleet || isPreregistered(employeeSelectedStatus));
    },
  },
  watch: {
    isElementVisible(value) {
      if (value) {
        const eventData = composeViewItemListData({
          locations: [this.location],
          city: this.city,
          currency: this.getCurrentOperatorCurrency,
        });

        this.trackEcommerceEvent({
          eventData,
          eventName: GTM_EVENTS.viewItemList,
        });
      }
    },
  },

  beforeMount() {
    this.headerAddress = this.getHeaderAddress(this.location);
    this.headerDates = [
      {
        label: this.$t('new_booking.picker.start'),
        date: printableDate(get(this, 'bookingPeriod.start')),
        testId: 'start',
      },
      {
        label: this.$t('new_booking.picker.end'),
        date: printableDate(get(this, 'bookingPeriod.end')),
        testId: 'end',
      },
    ];
  },

  mounted() {
    addNodeElement(this.classId);
    checkNodeVisibility();

    this.eventHandler.$on(this.events.MARKER_SELECTED, marker => {
      this.display.selected = (marker.data.uuid === this.location.uuid);
      if (this.display.selected) {
        this.locationSelected();
      }
    });
  },

  beforeDestroy() {
    this.eventHandler.$off(this.events.MARKER_SELECTED);
  },

  created() {
    this.VEHICLE_REQUIRED_PROPS = [
      'isInsuranceUpgradeAvailaible',
      'isAdditionalDriverEnabled',
      'isAdditionalPassengersEnabled',
      'isBookingRequest',
      'additionalDrivers',
      'passengers',
      'comment',
      'user',
      'csOperator',
      'isIntervention',
    ];
  },

  methods: {
    pick,
    printableAddress,
    setMarkerSelected() {
      const google = googleMapsLoader.getInstance();

      const list = this.mapManager.getMarkerList();
      const myMarker = list[`location_${this.location.uuid}`];

      if (myMarker) {
        google.maps.event.trigger(myMarker, 'click');
      }
    },
    locationSelected() {
      this.trackSegment({
        name: SEGMENT_EVENTS.LOCATION_SELECTED,
        data: {
          [SEGMENT_PARAM_NAMES.BOOKING_TYPE]: isLongDistance(this.getCurrentBookingType.code)
            ? SEGMENT_ENUMERATE_VALUES.BOOKING_TYPE.LONG_DISTANCE
            : SEGMENT_ENUMERATE_VALUES.BOOKING_TYPE.CAR_SHARING,
        },
      });
    },

    getHeaderAddress(location) {
      const locations = [];
      locations.push({
        icon: mapMarkerSelector.parkingIcon(),
        address: printableAddress(location),
        action: () => {
          this.locationDetailsModal = {
            isOpen: true,
            parentLocation: { uuid: location.original_uuid },
            mapManager: this.mapManager,
          };
        },
      });

      if (this.destinationLocation.uuid !== location.original_uuid) {
        locations.push({
          icon: mapMarkerSelector.dropOff(),
          address: printableAddress(this.destinationLocation),
          action: () => {
            this.locationDetailsModal = {
              isOpen: true,
              parentLocation: { uuid: this.destinationLocation.uuid },
              mapManager: this.mapManager,
            };
          },
        });
      }
      return locations;
    },
    selectVehicle({ vehicle, location }) {
      const eventData = {
        currency: this.currency,
        items: mapVehiclesData({
          vehicles: [vehicle],
          location: mapLocationData(location),
          city: this.city,
        }),
      };
      this.trackEcommerceEvent({
        eventData,
        eventName: GTM_EVENTS.selectItem,
      });
      this.trackEcommerceEvent({
        eventData,
        eventName: GTM_EVENTS.viewItem,
      });
      this.$emit('selected', location);
    },
  },
};

</script>
